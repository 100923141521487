<template>
  <CRow>
    <CModal title="View Comment" size="lg" :show.sync="largeModal" :no-close-on-backdrop="true" :hide-footer="true"
            color="primary">
      <CCardBody>
        <CCol md="12" class="align-self-center">
          <CRow>
            <CCol sm="6" md="4" lg="12">
              <p class="custome-labal">
                Comment :
              </p>
              <p class="custome-detail">
                {{ self.comment.comment }}
              </p>
            </CCol>
          </CRow>
        </CCol>
      </CCardBody>
    </CModal>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <back-to-list></back-to-list>
          <div class="row">
            <CCol sm="12" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton color="danger" v-c-tooltip="$lang.buttons.export.pdf"
                           v-on:click="exports(self, 'pdf', module, 'Event Comments',self.eventId)"><i
                      class="fas fa-file-pdf"></i></CButton>
                  <VueJsonToCsv class="btn btn-success" v-c-tooltip="$lang.buttons.export.excel" :json-data="json_data"
                                :labels="json_label" :csv-title="json_title">
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
                <BeforeFilter :filterShow="toggleFilterValue" @getFilterToggle="switchFilter"></BeforeFilter>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <div class="row">
            <CCol sm="12" class="align-self-center">
              <strong>Event : {{ self.eventName }} </strong>
            </CCol>
          </div>
          <br>
          <transition name="fade">
            <CRow v-if="toggleFilterValue">
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">Registered As</label>
                  <v-select :options="registeredTypes" @option:selected="getUsers"
                            v-model="filter.registeredType"></v-select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">Name</label>
                  <v-select :options="list" v-model="filter.list"></v-select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group" style="margin-top: 30px">
                  <CButton
                      type="submit"
                      size="sm"
                      color="primary"
                      @click="applyFilter()"
                      v-c-tooltip="$lang.common.filter.button.filter"
                  >
                    {{ $lang.common.filter.button.filter }}
                  </CButton>
                  &nbsp;
                  <CButton
                      color="danger"
                      size="sm"
                      @click="resetFilter()"
                      v-c-tooltip="$lang.common.filter.button.cancel"
                  >
                    {{ $lang.common.filter.button.cancel }}
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </transition>

          <v-server-table
              :columns="columns"
              :url="listUrl"
              :options="options"
              ref="myTable"
              @loaded="
                exports(self, 'Excel', module, 'Event Comments', self.eventId)
              "
          >
            <template #name="data">
              <Avatar
                  :image="data.row.avatar"
                  :content="data.row.name"
                  :isProfileListPage="true"
                  :length="20"
              />
            </template>
            <template #comment="data">
              <span :title="trimfunction(data.row.comment,50)">
                    {{ trimfunction(data.row.comment, 50) }}
              </span>
            </template>
            <template #createdAt="data">
              {{ dateTimeUTCtoLocalFormat(data.row.createdAt) }}
            </template>
            <template #updatedAt="data">
              {{ dateTimeUTCtoLocalFormat(data.row.updatedAt) }}
            </template>
            <template #actions="data">
              <CButton
                  color="primary"
                  v-on:click="viewComment(data.row.id)"
              >
                <i class="fas fa-eye"></i>
              </CButton>
            </template>
          </v-server-table>


        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {gAC} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import VueToasted from "vue-toasted";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";
import BackToList from "../../backToList";
import Avatar from "/src/components/Avatar";
import BeforeFilter from "../../BeforeFilterOpen";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);
export default {
  name: "GroupEventComments",
  mixins: [Mixin],
  components: {
    BeforeFilter,
    VueJsonToCsv,
    BackToList,
    Avatar
  },
  data() {
    return {
      list: [],
      events: [],
      types: [],
      registeredTypes: [],
      submitted: false,
      eventId: '',
      eventName: '',
      listUrl: '/users/groups/events/comment/list',
      json_data: [],
      messageColor: "success",
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      alertMessage: "",
      showAlert: false,
      dismissCountDownS: 0,
      msg: "",
      items: [],
      activePage: 1,
      selectedLetter: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      dismissCountDown: 0,
      statusOptions: [],
      self: this,
      module: gAC,
      comment: {
        comment: '',
      },
      maxlength: {
        // category: Maxlength.gac.name,
      },
      filter: {
        type: "",
        registeredType: "",
        list: "",
        event: "",
      },
      columns: ["registeredType", "name", "company", "comment", "createdAt", "updatedAt", "actions"],
      data: [],
      options: {
        headings: {
          company: this.$lang.gac.table.company,
          registeredType: this.$lang.gac.table.registeredType,
          name: this.$lang.events.table.comments.name,
          comment: this.$lang.events.table.comments.comment,
          createdAt: this.$lang.events.table.comments.created,
          updatedAt: this.$lang.events.table.comments.updated,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["name"],
        sortable: ["name", "comment", "createdAt"],
        filterable: ["name", "comment", "updatedAt"],
        // see the options API
      },
    };
  },
  validations: {},

  created() {
    let self = this;
    self.dismissCountDown = 0;
    self.eventId = this.$route.params.id;
    if (this.$route.params.id) {
      self.dismissCountDown = 0;
      self.listUrl = self.listUrl + "/" + this.$route.params.id;
    }
    self.eventName = localStorage.getItem('eventName');
    store.commit('showLoader', false); // Loader Start
  },
  mounted() {
    store.commit('showLoader', false); // Loader Start
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    // self.data = this.loadData();
    self.list = [];
    self.registeredTypes.push(
        {value: "Company", label: 'Company'},
        {value: "Individual", label: 'Individual'},
        {value: "Member", label: 'Member'},
        {value: "Users", label: 'All Users'}
    );
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    /* get from child component */
    switchFilter(show) {
      this.toggleFilterValue = show;
    },

    getUsers(e) {
      let type = e.value;
      this.getList(type);
    },
    getList(type) {
      let self = this;
      self.userList = "/users/groups/events/comment/list/" + this.$route.params.id;
      axios.get(self.userList)
          .then((response) => {
            let responseData = response.data;
            self.list = [];
            responseData.data.map(function (value, key) {
              if (type === 'Users') {
                self.list.push({value: value.userId, label: value.firstName});
              } else if (value.registeredType === type && type === 'Company') {
                self.list.push({value: value.companyId, label: value.company});
              } else if (value.registeredType === type && type !== 'Company') {
                self.list.push({value: value.userId, label: value.name});
              }
            });
            self.list = this.removeDuplicateValues(self.list);
          });
    },

    applyFilter() {
      let self = this;
      let reqObj = {
        registeredType: Object.keys(this.filter.registeredType).length > 0 ? this.checkUndefinedNull(self.filter.registeredType.value) : null,
        id: Object.keys(this.filter.list).length > 0 ? this.checkUndefinedNull(self.filter.list.value) : null,
      };
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = reqObj;
      this.$refs.myTable.refresh();
    },
    resetFilter() {
      let self = this;
      self.filter.registeredType = [];
      self.filter.list = [];
      store.commit("showLoader", true);
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();
    },

    createTableRow() {
    },
    viewComment(id) {
      let self = this;
      axios.get('users/groups/events/comment/view/' + id).then((response) => {
        if (response) {
          let responseData = response.data.data;
          self.comment.comment = responseData.comment;
        } else {
          self.data = [];
        }
      });
      self.largeModal = true;
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
